import {getTranslations, isWorker} from '@wix/wixstores-client-storefront-sdk/dist/src/viewer-script/utils';
import {IHostProps} from '@wix/native-components-infra/dist/src/types/types';
import {SiteCurrency} from '@wix/ambassador-currency-site-settings/types';
import {CurrencySymbols, CurrencySymbolsMap} from '../../settings/services/currency.utility';
import {CurrencyService} from '../../services/CurrencyService';
import {APP_DEFINITION_ID, WIDGET_ID} from '../constants';
import {CurrencyConverterSiteStore} from './currencyConverterSiteStore';
import {GUID} from '@wix/bi-logger-ec-sf';
import {currencyConverterChooseSiteCurrencyDisplay} from '@wix/bi-logger-ec-sf/v2';

export interface IPropsInjectedByViewerScript {
  currencySymbols: CurrencySymbols;
  userCurrencies: string[];
  selectedCurrency: string;
  onCurrencySelected: (code: string) => void;
  onAppLoaded: () => void;
  reportAppNotLoaded: CurrencyConverterStore['reportAppNotLoaded'];
  translations: any;
  isRtl: boolean;
  isMobile: boolean;
  host: IHostProps;
  ravenUserContextOverrides: {id: string; uuid: GUID};
}

export class CurrencyConverterStore {
  private readonly updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void;
  private readonly currencyService: CurrencyService;
  private currencySymbols: CurrencySymbols;
  private userCurrencies: string[];
  private selectedCurrency: string;
  private readonly siteStore: CurrencyConverterSiteStore;
  private readonly fedopsLogger;
  private shouldReportFedops: boolean;
  private readonly reportError: any;

  constructor({
    siteStore,
    currencyService,
    updateComponent,
    reportError,
  }: {
    siteStore: CurrencyConverterSiteStore;
    currencyService: CurrencyService;
    updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void;
    reportError: any;
  }) {
    const fedopsLoggerFactory = siteStore.platformServices.fedOpsLoggerFactory;
    this.reportError = reportError;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: WIDGET_ID,
    });

    if (isWorker()) {
      this.shouldReportFedops = true;
      this.fedopsLogger.appLoadStarted();
    }

    this.updateComponent = updateComponent;
    this.currencyService = currencyService;
    this.siteStore = siteStore;

    this.siteStore.location.onChange(() => {
      if (this.selectedCurrency !== this.siteStore.location.query.currency) {
        this.updateComponent({
          selectedCurrency: this.siteStore.location.query.currency || this.siteStore.siteMainCurrency,
        });
      }
    });
  }

  private readonly onCurrencySelected = (currency: string) => {
    if (currency === this.siteStore.siteMainCurrency) {
      this.siteStore.removeQueryParams(['currency']);
    } else {
      this.siteStore.updateQueryParams({currency});
    }
    this.siteStore.webBiLogger.report(
      currencyConverterChooseSiteCurrencyDisplay({
        newValue: currency,
        siteCurrency: this.siteStore.siteMainCurrency,
      })
    );
  };

  private getUserCurrenciesWithMainSiteCurrencyFirst(mainCurrency: string, additionalCurrencies: SiteCurrency[]) {
    return [mainCurrency, ...additionalCurrencies.map(({code}) => code).filter((code) => code !== mainCurrency)];
  }

  private async getTranslations(locale, fallbackLocale) {
    let translations;
    try {
      translations = await getTranslations(
        `${this.siteStore.baseUrls.currencyConverterBaseUrl}assets/locales/widget/messages_${this.siteStore.locale}.json`
      );
    } catch (ex) {
      translations = await getTranslations(
        `${this.siteStore.baseUrls.currencyConverterBaseUrl}assets/locales/widget/messages_${fallbackLocale}.json`
      );
    }

    return translations;
  }

  public async setInitialState(reportError): Promise<any> {
    this.selectedCurrency = this.siteStore.currentCurrencyCode;
    this.userCurrencies = this.getUserCurrenciesWithMainSiteCurrencyFirst(this.siteStore.siteMainCurrency, [
      {code: this.selectedCurrency},
    ]);

    const [translations] = await Promise.all([
      this.getTranslations(this.siteStore.locale, 'en'),
      this.loadInitialData(reportError),
    ]).catch(reportError);

    this.updateComponent({
      ravenUserContextOverrides: {
        id: this.siteStore.storeId,
        uuid: this.siteStore.uuid,
      },
      userCurrencies: this.userCurrencies,
      currencySymbols: this.currencySymbols,
      selectedCurrency: this.selectedCurrency,
      onAppLoaded: this.onAppLoaded,
      onCurrencySelected: this.onCurrencySelected,
      translations,
      isRtl: this.siteStore.isRTL(),
      isMobile: this.siteStore.isMobile(),
    });

    /* istanbul ignore next: need test - ssr  */
    if (this.siteStore.isSSR()) {
      this.onAppLoaded();
    }
  }

  public onAppLoaded = (): void => {
    if (this.shouldReportFedops) {
      this.fedopsLogger.appLoaded();
      this.shouldReportFedops = false;
    }
  };

  public reportAppNotLoaded = (selectedCurrency: string, onAppLoaded): void => {
    const message = `selectedCurrency = ${selectedCurrency}, type onAppLoaded === 'function' = ${
      typeof onAppLoaded === 'function'
    }`;
    this.reportError(new Error(message));
  };

  public async loadInitialData(reportError): Promise<void> {
    try {
      const [allCurrencies, userCurrencies] = await Promise.all([
        this.currencyService.getAvailableCurrenciesList(),
        this.currencyService.getCurrencies(),
      ]);

      this.currencySymbols = CurrencySymbolsMap(allCurrencies);

      this.selectedCurrency = this.siteStore.currentCurrencyCode;
      this.userCurrencies = this.getUserCurrenciesWithMainSiteCurrencyFirst(
        this.siteStore.siteMainCurrency,
        userCurrencies
      );
      const selectedCurrency = this.userCurrencies.find((code) => code === this.selectedCurrency);
      if (!selectedCurrency) {
        this.userCurrencies.push(this.selectedCurrency);
      }

      this.updateComponent({
        userCurrencies: this.userCurrencies,
        currencySymbols: this.currencySymbols,
      });
    } catch (e) {
      reportError(e);
      throw e;
    }
  }
}
